import PropTypes from 'prop-types';
import { getAuth } from '@services/identity.service';
import { useContext, useEffect, useRef } from 'react';
import useScrollToTopOnRouteChange from '@ui/hooks/useScrollToTopOnRouteChange';
import AuthContext from '@context/auth';
import { useRouter } from 'next/router';

const EmptyLayout = ({ children }) => {
    const { loginOrSignupModal, logout } = useContext(AuthContext);
    useScrollToTopOnRouteChange();
    // code for auto-logging out after 2 hrs of inactivity
    const debounceRef = useRef();
    const time = 1000 * 60 * 60 * 2; // 2 hrs
    const router = useRouter();

    useEffect(() => {
        debounceRef.current();
        tracker();
    }, []);

    debounceRef.current = (function debounce() {
        let timerId;

        return function () {
            if (timerId) {
                clearTimeout(timerId);
            }

            timerId = setTimeout(() => {
                if (getAuth()) {
                    logout();
                }
            }, time);
        };
    })();

    function tracker() {
        window.addEventListener('mousemove', debounceRef.current);
        window.addEventListener('scroll', debounceRef.current);
        window.addEventListener('keydown', debounceRef.current);
    }

    return (
        <div className="page-content">
            {children}
            {!router.pathname.includes('/admin') && loginOrSignupModal()}
        </div>
    );
};

EmptyLayout.propTypes = {
    children: PropTypes.node,
};

export default EmptyLayout;
