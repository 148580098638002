import { useEffect } from 'react';
import { useRouter } from 'next/router';

const useScrollToTopOnRouteChange = () => {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }, 50);
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);
};

export default useScrollToTopOnRouteChange;